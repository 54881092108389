html,
body {
  height: 100%;
  background-color: #f5f5f5;
}

.row {
  margin-bottom: 1rem;
}

.nav-tabs {
  margin-bottom: 1rem;
}

.process-list li {
  padding-bottom: 1rem;
}
